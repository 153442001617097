<template>
  <main>
    <sqr-hero title="profile_title" subtitle="profile_subtitle" color="light" is-bold />
    <section class="section">
      <div class="container">
        <div class="columns is-centered is-variable is-8">
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <sqr-progress v-show="!record"/>
            <div v-if="record">
              <sqr-input-text
                class="field"
                label="profile_givenname"
                :value="record.givenName"
                @change="fieldSetAndDisplayName({field: 'givenName', value: $event})"
              />
              <sqr-input-text
                class="field"
                label="profile_familyname"
                :value="record.familyName"
                @change="fieldSetAndDisplayName({field: 'familyName', value: $event})"
              />
              <sqr-input-text
                class="field"
                label="profile_company"
                :value="record.company"
                @change="fieldSet({field: 'company', value: $event})"
              />
              <sqr-input-email
                class="field"
                label="profile_email"
                :value="user.email"
                @change="emailChange"
              />
              <div class="field" v-if="user && !user.emailVerified">
                <sqr-button
                  class="field"
                  icon="check"
                  label="profile_email_verify"
                  @click="emailVerify()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputEmail from '@/sqrd/SqrInputEmail';

export default {
  name: 'Profile',
  components: { SqrInputText, SqrInputEmail },
  computed: {
    ...mapGetters('auth', ['user', 'uid']),
    ...mapGetters('user', [
      'record',
      'loading',
      'loadError',
      'saving',
      'saveError'
    ]),
    allTopics () {
      return ['alerts', 'reviews', 'highlights', 'events', 'rsvps', 'admin'];
    }
  },
  mounted() {
    this.subscribe({ id: this.uid });
    this.topicsLoad({});
  },
  methods: {
    ...mapActions('user', ['subscribe', 'fieldSet']),
    async fieldSetAndDisplayName(payload) {
      try {
        await this.fieldSet(payload);
        const name = [this.record.givenName, this.record.familyName]
          .filter(v => v)
          .join(' ');
        this.displayNameChange(name);
      } catch (error) {
        console.log(error);
      }
    },
    async displayNameChange(displayName) {
      try {
        const res = await this.displayNameSet({ displayName });
        console.log(displayName, res);
      } catch (error) {
        console.log(error);
      }
    },
    async emailChange(value) {
      try {
        return await this.$fb()
          .auth()
          .currentUser.updateEmail(value);
      } catch (error) {
        console.log(error);
      }
    },
    async emailVerify() {
      try {
        const res = await this.$fb()
          .auth()
          .currentUser.sendEmailVerification();
        console.log(res);
        alert(this.$t('profile_email_launched'));
        return res;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
